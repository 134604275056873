import React from 'react';

type Props = {
  style?: React.CSSProperties;
  className?: string;
  width: number;
  height: number;
  path: string;
};

const Dashes = ({className, style, width, height, path}: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    role="presentation"
    height={height}
    width={width}
    viewBox={`0 0 ${width} ${height}`}
    className={className}
    style={{
      display: 'block',
      zIndex: -1,
      ...style
    }}
  >
    <path d={path} stroke="#000" fill="none" strokeDasharray="5 13"/>
  </svg>
);

export default Dashes;
