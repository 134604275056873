import React from 'react';
import {Link} from 'gatsby';
import clsx from 'clsx';

import {button, secondary, outline} from './styles/button.module.scss';

type Props = {
  to: string;
  children: React.ReactNode[] | React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
  isSecondary?: boolean;
  isOutline?: boolean;
};

const ButtonLink = ({
  to,
  className,
  style,
  children,
  isSecondary,
  isOutline,
  ...props
}: Props) => (
  <Link
    to={to}
    className={clsx(button, className, {
      [secondary]: isSecondary,
      [outline]: isOutline
    })}
    style={style}
    {...props}
  >
    {children}
  </Link>
);

export default ButtonLink;
