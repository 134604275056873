import React from "react";
import { graphql, Link } from "gatsby";
import { GatsbyImage, getSrc, IGatsbyImageData } from "gatsby-plugin-image";

import Layout from "../components/layout";
import ButtonLink from "../components/button-link";
import Dashes from "../components/dashes";

import {
  body,
  description,
  toolLinks,
  figure,
  screenshot as screenshot_style,
  getStarted,
} from "./styles/tool.module.scss";

const TOOLS = [
  {
    name: "Reader",
    url: "/reader/",
  },
  {
    name: "Writer",
    url: "/writer/",
  },
  {
    name: "WordHelper",
    url: "/wordHelper/",
  },
  {
    name: "Extension",
    url: "/extension/",
  },
];

const ToolPage = ({
  data,
}: {
  data: {
    markdownRemark: {
      frontmatter: {
        alt: string;
        title: string;
        metaDescription: string;
        screenshot: any;
        video: any;
      };
      html: string;
    };
  };
}) => {
  const { markdownRemark } = data;
  const {
    frontmatter: { alt, title, metaDescription, screenshot, video },
    html,
  } = markdownRemark;

  return (
    <Layout title={title} metaDescription={metaDescription}>
      <Dashes
        height={762}
        path="M73 0c93 213 93 350 0 411s-96 178-11 351"
        style={{
          position: "absolute",
          left: 0,
          right: 0,
          top: 0,
          margin: "0 auto",
        }}
        width={142}
      />
      <div className={body}>
        <div className={description}>
          {/* eslint-disable-next-line react/no-danger */}
          <div dangerouslySetInnerHTML={{ __html: html }} />
          <p>Learn about the other tools:</p>
          <p className={toolLinks}>
            {TOOLS.map((tool) => (
              <Link
                key={tool.name}
                activeStyle={{ display: "none" }}
                to={tool.url}
              >
                {tool.name}
              </Link>
            ))}
          </p>
        </div>

        <figure className={figure}>
          {video ? (
            <div
              style={{
                height: 0,
                paddingBottom: `${
                  video.videoScreenshots[0].childImageSharp.gatsbyImageData
                    .height * 100
                }%`,
              }}
            >
              <video
                autoPlay
                loop
                muted
                poster={getSrc(video.videoScreenshots[0] as IGatsbyImageData)}
                style={{
                  width: "100%",
                }}
              >
                <source src={video.videoH264.path} />
              </video>
            </div>
          ) : (
            <GatsbyImage
              image={screenshot.childImageSharp.gatsbyImageData}
              className={screenshot_style}
              alt={alt}
            />
          )}

          <figcaption>{alt}</figcaption>
        </figure>
      </div>
      <div className={getStarted}>
        <ButtonLink to="/tools/">Get Started</ButtonLink>
      </div>
    </Layout>
  );
};

// eslint-disable-next-line @typescript-eslint/no-confusing-void-expression
export const pageQuery = graphql`
  query ($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        title
        metaDescription
        screenshot {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        video {
          videoH264 {
            path
          }
          videoScreenshots {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
        alt
      }
    }
  }
`;

export default ToolPage;
